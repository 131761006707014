import { render, staticRenderFns } from "./manager.vue?vue&type=template&id=44e2d6cd"
var script = {}
import style0 from "./manager.vue?vue&type=style&index=0&id=44e2d6cd&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports